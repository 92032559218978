import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FaUserNurse } from 'react-icons/fa';
import { BsArrowLeft, BsFillBookFill } from 'react-icons/bs';

import { Link } from "react-router-dom";

import Table from '../../components/Table/Table';
import Filters from "components/Table/Filters";

import { useWorkerListSlice } from "../../reducers/treballadors/index";
import { useDispatch, useSelector } from "react-redux";

import {
    Bg,
    Back,
    CreateUser
} from './components';


const crearTreballador = () => {
    const dni = document.querySelector(".createUser > input[name='dni']").value;
    const name = document.querySelector(".createUser > input[name='name']").value;
    const role = document.querySelector(".createUser > input[name='role']").value;
    // const role = document.querySelector("select").value;

    console.log({ dni, name, role })
}

function TreballadorsView() {
      const dispatch = useDispatch();
      const { workerListActions, selectWorkerListDomain } = useWorkerListSlice();
      const { workerList } = useSelector(selectWorkerListDomain);
      

        useEffect(() => {
            dispatch(workerListActions.getWorkerListRequest());
       }, [dispatch, workerListActions])
    ;
    

    const filters = [
        { placeholder: 'DNI', name: 'id' },
        { placeholder: 'Nom', name: 'name' },
        { placeholder: 'Càrrec', name: 'role' },
    ];

    return (
        <Bg>
            <Back>
                <BsArrowLeft />
                <Link exact to='/private/home' />
            </Back>
            <div className="pageHeader">
                <div className="icon"> <FaUserNurse /> </div>
                <div className="sectionName">
                    <h1>Treballadors</h1>
                    <p>Tots els treballadors</p>
                </div>
            </div>
            <CreateUser className="createUser">
                <input type='text' name='dni' placeholder="DNI" />
                <input type='text' name='name' placeholder="Nom" />
                <input type='text' name='role' placeholder="Càrrec" />
                {/* { <select>
                    <option>Desenvolupador front-end</option>
                    <option>Desenvolupador back-end</option>
                </select> } */}
                <button
                    type='button'
                    className="btnBlue"
                    style={{ '--width': '80px' }}
                    onClick={() => crearTreballador()}
                >Crear</button>
            </CreateUser>
            <Filters filters={filters} />
            <Table type='treballadors' />
        </Bg>
    )
}



export default TreballadorsView;